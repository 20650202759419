import React, { useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { useDropzone } from 'react-dropzone';
import { Trash2 } from 'react-feather';
import { AiOutlineCloudUpload } from "react-icons/ai";
import imageCompression from 'browser-image-compression';
import axios from 'axios';
import { v4 } from 'uuid';

const UploadHelper2 = (props) => {
    const [uploadError, setUploadError] = useState('');
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [uploadStatus, setUploadStatus] = useState(false);

    const uploadToCloud = async (file, presignedUrl) => {
        try {
            if (file == null) return;
            await axios.put(presignedUrl, file, {
                headers: {
                    'Content-Type': file.type,
                },
            });
            return presignedUrl.split('?')[0]; // Return the S3 file URL
        } catch (err) {
            throw new Error('Upload failed');
        }
    };

    const handleFiles = async (files) => {
        setUploadStatus(true); // Start upload status
        let totalFiles = files.length;
        let uploadedFiles = 0;

        try {
            for (const file of files) {
                if (file.size > 10485760) {
                    setUploadError('Select File Size up to 10 MB');
                    throw new Error('File size too large'); // Exit if file size is too large
                }

                let fileToUpload = file;
                if (file.type.includes('image')) {
                    fileToUpload = await imageCompression(file, {
                        maxSizeMB: 10,
                        maxWidthOrHeight: 2000,
                    });
                }

                const { data } = await axios.get(
                    'https://bestmate.us/aws/generate-presigned-portal-url',
                    {
                        params: {
                            filename: `${v4()}-${fileToUpload.name}`,
                            filetype: fileToUpload.type,
                        },
                    }
                );

                const location = await uploadToCloud(fileToUpload, data.url);
                setSelectedFiles((old) => [...old, { image: location }]);

                uploadedFiles++;
                if (uploadedFiles === totalFiles) {
                    setUploadStatus(false); // All files uploaded
                }
            }
        } catch (err) {
            console.error(err);
            setUploadStatus(false); // Stop upload status in case of error
            setUploadError('Something went wrong!');
        }
    };

    const { getRootProps, getInputProps } = useDropzone({
        disabled: uploadStatus ? true : false,
        accept: { 'image/*': [] },
        onDrop: async (acceptedFiles, error) => {
            console.log(error, acceptedFiles);
            if (error.length !== 0) {
                setUploadError(error[0].errors[0].message);
            } else {
                setUploadError('');
                try {
                    await handleFiles(acceptedFiles); // Pass all accepted files to handleFiles
                } catch (err) {
                    console.log(err);
                    setUploadStatus(false);
                    setUploadError('Something went wrong!');
                }
            }
        },
    });

    // Function to delete a file
    const handleDelete = (index) => {
        const updatedFiles = selectedFiles.filter((_, i) => i !== index);
        setSelectedFiles(updatedFiles);
    };

    useEffect(() => {
        props.setFieldValue(props.name, [...selectedFiles]);
    }, [selectedFiles]);

    return (
        <>
            <section>
                <div {...getRootProps()}>
                    <input {...getInputProps()} />
                    <div
                        style={{
                            width: '100%',
                            height: 120,
                            backgroundColor: '#f7f7f7',
                            borderRadius: 5,
                            border: '1px dashed #c9c8c7',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            padding: 5,
                            cursor: 'pointer',
                        }}
                    >
                        {uploadStatus ? (
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    flexDirection: 'column',
                                }}
                                onClick={() => {}}
                            >
                                <Spinner
                                    animation='border'
                                    size='md'
                                    style={{ color: '#407FEA' }}
                                />
                                <div style={{ marginTop: 20 }}>Uploading</div>
                            </div>
                        ) : (
                            <>
                                <AiOutlineCloudUpload
                                    size={30}
                                    strokeWidth={1}
                                    stroke={'#B3BCCD'}
                                />
                                <div
                                    style={{
                                        fontSize: '15px',
                                        fontWeight: 'bold',
                                        marginTop: 3,
                                    }}
                                >
                                    <span>Upload a file</span>
                                </div>
                                <div style={{
                                        fontSize: '11px',
                                    }}>Drag and drop files here (max 10MB each)</div>
                            </>
                        )}
                        {uploadError ? (
                            <div
                                style={{
                                    color: 'red',
                                    fontSize: 11,
                                    marginTop: 10,
                                }}
                            >
                                {uploadError}
                            </div>
                        ) : (
                            <></>
                        )}
                    </div>
                </div>
            </section>
            <section style={{ marginTop: 20 }}>
                {selectedFiles.length > 0 && (
                    <div>
                        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                            {selectedFiles.map((file, index) => (
                                <div
                                    key={index}
                                    style={{
                                        marginRight: 15,
                                        marginBottom: 15,
                                        position: 'relative',
                                        border: '1px solid #bab5b5',
                                        borderRadius: 5,
                                        padding: 5,
                                    }}
                                >
                                    <img
                                        src={file.image}
                                        alt={`uploaded-${index}`}
                                        style={{
                                            width: 70,
                                            height: 70,
                                            objectFit: 'cover',
                                            borderRadius: 5,
                                            backgroundColor: '#e3e1e1',
                                        }}
                                    />
                                    <Trash2
                                        size={20}
                                        strokeWidth={2}
                                        stroke={'#D24232'}
                                        style={{
                                            marginLeft: 15,
                                            marginRight: 10,
                                            cursor: 'pointer',
                                        }}
                                        onClick={() => handleDelete(index)}
                                    />
                                </div>
                            ))}
                        </div>
                    </div>
                )}
            </section>
        </>
    );
};
export default UploadHelper2;
