import React, { useState } from "react";

const initialState = {
  discount_rate: "",
  notes: "",
  currency_code: "",
  // invoice_partner_id: data.invoice_partner_id,
  // invoice_partner_type: data.invoice_partner_type,
  business_id: "",
  tax_rate: "",
  order: [],
  partner: {},
  reference_number: "",
  grand_total: "",
  number: "",
  workflow_type: "",
  settled_amount: "",
  created_at: "",
  id: "",
  reminders: [],
};

const FormContext = React.createContext(initialState);

function FormProvider({ children }) {
  const [number, setNumber] = useState("");
  return (
    <FormContext.Provider value={{ number, setNumber }}>
      {children}
    </FormContext.Provider>
  );
}

export { FormContext, FormProvider };
