import React, { useState } from "react";

const SelectedContext = React.createContext();

export const useSelected = () => React.useContext(SelectedContext);

export const SelectedProvider = ({ children }) => {
  const [selected, setSelected] = useState("Home");

  return (
    <SelectedContext.Provider value={{ selected, setSelected }}>
      {children}
    </SelectedContext.Provider>
  );
};
