const ErrorScreen = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "white",
        height: "100vh",
        fontSize: "2rem",
        fontWeight: "bold",
        color: "black",
      }}
    >
      No Business Available
    </div>
  );
};

export default ErrorScreen;
